
import { defineComponent, PropType, computed } from 'vue'
import { DropdownButton, DropdownItem } from '@bd/components'

export default defineComponent({
  components: {
    DropdownButton,
  },
  props: {
    selectedItem: {
      type: Object as PropType<DropdownItem<unknown>>,
      required: false,
    },
    items: {
      type: Array as PropType<DropdownItem<unknown>[]>,
      validator: (value: DropdownItem<unknown>[]) => !!value.length,
      required: true,
    },
    titleLabel: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:selectedItem'],
  setup(props) {
    const resolvedSelectedItem = computed<DropdownItem<unknown>>(() => {
      return props.selectedItem || props.items[0]
    })
    return {
      resolvedSelectedItem,
    }
  },
})
