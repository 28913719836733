<template>
  <div class="dashboard-content-switcher">
    <MultiLabelDropdown
      :items="offersContentOptions"
      :active="activeContentTab === 'Offers'"
      v-model:selectedItem="selectedOfferOption"
      :titleLabel="t('offers.title')"
      @update:selectedItem="offersOptionSelected"
    />
    <MultiLabelDropdown
      :items="clientsContentOptions"
      :active="activeContentTab === 'Clients'"
      v-model:selectedItem="selectedClientOption"
      :titleLabel="t('clients.title')"
      @update:selectedItem="clientsOptionSelected"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed } from 'vue'
import { MultiLabelDropdown } from '@bd/components/MultiLabelDropdown'
import { DropdownItem } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { DashboardContentTab, DashboardContentListType } from './types'

export default defineComponent({
  components: { MultiLabelDropdown },
  props: {
    activeContentTab: {
      type: String as PropType<DashboardContentTab>,
      required: true,
    },
  },
  emits: ['update:activeContentTab', 'select-item'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const offersContentOptions = computed(() =>
      Object.keys(DashboardContentListType)
        .map((value) => ({
          label: t(`dashboard_content_list_type.offers.${value}`),
          value: value,
        }))
        .reverse(),
    )
    const clientsContentOptions = computed(() =>
      Object.keys(DashboardContentListType)
        .map((value) => ({
          label: t(`dashboard_content_list_type.clients.${value}`),
          value: value,
        }))
        .reverse(),
    )

    const selectedOfferOption = ref<DropdownItem<string> | null>(
      offersContentOptions.value[0],
    )
    const selectedClientOption = ref<DropdownItem<string> | null>(null)

    const offerItemsDropdownHidden = ref(false)

    const switchContent = (contentTab: DashboardContentTab) => {
      emit('update:activeContentTab', contentTab)

      const option =
        selectedClientOption.value?.value || selectedOfferOption.value?.value
      const obj = {
        tab: contentTab,
        option,
      }

      emit('select-item', obj)
    }

    const offersOptionSelected = (_: DropdownItem<string>) => {
      selectedClientOption.value = null
      switchContent('Offers')
    }
    const clientsOptionSelected = (_: DropdownItem<string>) => {
      selectedOfferOption.value = null
      switchContent('Clients')
    }

    return {
      t,
      offersContentOptions,
      clientsContentOptions,
      selectedOfferOption,
      selectedClientOption,
      offerItemsDropdownHidden,
      offersOptionSelected,
      clientsOptionSelected,
    }
  },
})
</script>

<style lang="scss" scoped>
.dashboard-content-switcher {
  margin: 0 -#{$multilabel-gutter-size};
  display: block;

  > * {
    padding: 0 #{$multilabel-gutter-size};
  }

  @include breakpoint-down(md) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1 0 100%;
    > * {
      flex: 1 0 auto;
      width: 50%;
      padding: #{$multilabel-gutter-size};
    }
  }
}
</style>
