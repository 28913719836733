<template>
  <DropdownButton contentClass="dropdown-filter-content">
    <Button
      class="toggle-button p-button-sm text-500 p-button-rounded"
      :class="[active ? 'active p-button-primary' : 'p-button-secondary']"
    >
      <span
        class="p-button-label d-flex flex-column align-items-start p-overflow-hidden"
      >
        <label class="main w-100 text-truncate">{{ titleLabel }}</label>
        <label class="selected-option w-100 text-truncate">
          {{ resolvedSelectedItem.label }}
        </label>
      </span>
      <span class="pi pi-chevron-down p-button-icon p-button-icon-right"></span>
    </Button>

    <template #content>
      <ul class="dropdown-list">
        <li
          v-for="(item, index) of items"
          :key="index"
          class="dropdown-item"
          :class="{ selected: selectedItem === item }"
          @click="$emit('update:selectedItem', item)"
        >
          <div class="item-wrapper">
            <label class="text-truncate">{{ item.label }}</label>
            <i class="pi pi-chevron-right" />
          </div>
        </li>
      </ul>
    </template>
  </DropdownButton>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { DropdownButton, DropdownItem } from '@bd/components'

export default defineComponent({
  components: {
    DropdownButton,
  },
  props: {
    selectedItem: {
      type: Object as PropType<DropdownItem<unknown>>,
      required: false,
    },
    items: {
      type: Array as PropType<DropdownItem<unknown>[]>,
      validator: (value: DropdownItem<unknown>[]) => !!value.length,
      required: true,
    },
    titleLabel: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:selectedItem'],
  setup(props) {
    const resolvedSelectedItem = computed<DropdownItem<unknown>>(() => {
      return props.selectedItem || props.items[0]
    })
    return {
      resolvedSelectedItem,
    }
  },
})
</script>

<style lang="scss" scoped>
$dropdown-list-border-radius: 4px;
.dropdown-wrapper {
  min-width: 150px;
}
.toggle-button {
  width: 100%;
  height: 55px;
  &.p-button-outlined {
    background-color: $white;
  }

  label {
    text-align: left;
    cursor: inherit;
    &.main {
      font-weight: 500;
      font-size: 12px;
      color: $manatee;
    }
    &.selected-option {
      font-size: 14px;
      font-weight: 600;
      color: $port-gore;
    }
  }

  > span.pi {
    border-radius: 50%;
    padding: 0.25rem;
    border: 1px solid $athens-gray;
  }

  &.active {
    label {
      &.main {
        color: $white;
      }
      &.selected-option {
        color: $white;
      }
    }
    > span.pi {
      border: 1px solid $iron;
    }
  }
  &:not(.active) {
    box-shadow: 0 1px 2px 0 rgba(23, 25, 51, 0.08);
  }
}

.dropdown-list {
  background: $white;
  border-radius: $dropdown-list-border-radius;
  box-shadow: 0 25px 20px 0 $light-shadow;
  width: 100%;

  cursor: pointer;

  .dropdown-item {
    font-size: 14px;
    padding: 0 2rem;
    @include breakpoint-down(md) {
      padding: 0 1rem;
    }

    &:first-of-type {
      border-top-left-radius: $dropdown-list-border-radius;
      border-top-right-radius: $dropdown-list-border-radius;
    }

    &:last-of-type {
      border-bottom-left-radius: $dropdown-list-border-radius;
      border-bottom-right-radius: $dropdown-list-border-radius;
    }

    &:not(:last-of-type) > .item-wrapper {
      border-bottom: 1px solid rgba($athens-gray, 0.5);
    }

    &.selected {
      font-weight: 600;
      .item-wrapper > label {
        color: $primary-color;
      }
    }

    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 0;
      > label {
        color: $bright-gray;
        cursor: inherit;
      }
      i {
        color: $primary-color;
        margin-left: 2rem;
      }
    }

    transition: background-color 0.2s;
    &:hover:not(.selected) {
      background-color: darken($white, 1%);
    }
  }
}
:deep(.dropdown-filter-content) {
  max-width: 240px;
  min-width: calc(100% - #{$multilabel-gutter-size * 2});
  width: auto !important;
}
@include breakpoint-down(md) {
  :deep(.dropdown-filter-content) {
    max-width: calc(100% - #{$multilabel-gutter-size * 2});
  }
}
</style>
