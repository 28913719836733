<template>
  <Loader :is-loading="isLoading" />

  <div class="row py-2 d-md-none">
    <FiltersChip
      :activeFilters="activeFilters"
      :citiesAndProvinces="citiesAndProvinces"
      :districts="districts"
      @clear-filter="$emit('clear-filter', $event)"
    />
  </div>

  <InfiniteScroll
    class="card-grid"
    :pageSize="pageSize"
    :getPage="getOffersPage"
    :getItemKey="getOfferId"
    @is-loading="isLoading = $event"
    scrollElem=".dashboard-wrapper"
  >
    <template #empty>
      <EmptyState
        class="empty-state-dashboard"
        :title="t('offers.emptyState.title')"
        :description="t('offers.emptyState.description')"
      />
    </template>
    <template #default="{ item }">
      <div>
        <DashboardCard
          :displayed-icon-details="['rooms', 'area', 'floor']"
          :item="item"
          :isDraft="item.draft"
          :avatar="{
            userType: 'Agent',
            user: {
              firstName: item.sellerFirstName,
              lastName: item.sellerLastName,
            },
          }"
          @card-click="
            $router.push({ name: 'DashboardOffer', params: { id: item.id } })
          "
        />
      </div>
    </template>
  </InfiniteScroll>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, shallowRef } from 'vue'
import {
  DashboardCard,
  Loader,
  FiltersChip,
  determinePageSize,
} from '@bd/components'
import { agentApi, AgentOfferRowDto } from '@bd/api'
import { InfiniteScroll } from '@bd/components'
import { useAppStore } from '@bd/agent/store'
import { ComputedFilter } from '@bd/store-modules/types'
import { CityWithProvinceDto } from '@bd/api'
import { showDraftsOffers } from '@bd/agent/config/common.json'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DashboardOfferList',
  components: {
    DashboardCard,
    Loader,
    FiltersChip,
    EmptyState,
    InfiniteScroll: InfiniteScroll.InfiniteScroll,
  },
  emits: ['clear-filter'],
  props: {
    activeFilters: {
      type: Array as PropType<ComputedFilter[]>,
      required: true,
    },
    citiesAndProvinces: {
      type: Array as PropType<CityWithProvinceDto[]>,
      required: true,
    },
  },
  setup() {
    const store = useAppStore()
    const isLoading = shallowRef(false)
    const i18n = useI18n()

    const districts = computed(() => store.state.offers?.districts)

    // not reactive on purpose. InfiniteScroll component has internal logic that
    // adjusts number of pages fetched in parallel depending on container size.
    const pageSize = determinePageSize()

    const getOffersPage = computed<InfiniteScroll.GetPageFn>(() => {
      const filters = store.state.offers?.searchedFilters
      return async (page) => {
        const { data } = await agentApi.offers({
          ...filters,
          ...page,
          showDrafts: showDraftsOffers,
        })
        return data
      }
    })

    const getOfferId = (offer: AgentOfferRowDto) => {
      return offer.id
    }

    return {
      t: i18n.t,
      pageSize,
      getOffersPage,
      getOfferId,
      isLoading,
      districts,
    }
  },
})
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 60px;
}
.empty-state-dashboard {
  height: 80vh;
  grid-column: 1 / -1;
}
</style>
