<template>
  <div class="card" :class="cardClasses">
    <div class="card-content">
      <UserAvatar
        :userType="userType"
        :user="{ firstName: user.firstName, lastName: user.lastName }"
      />
      <div
        class="card-details"
        :style="`flex-direction: ${isIdReversed ? 'column-reverse' : 'column'}`"
      >
        <div>
          <span class="h-4 text-break-word">
            {{ userName }}
          </span>
        </div>
        <div class="caption" v-if="display.id">
          {{ user.displayableId ?? user.id }}
        </div>
      </div>
      <div class="my-4" v-if="display.details">
        <div class="d-flex align-items-center">
          <Svg :src="require('@bd/assets/icons/mail.svg')" class="mr-2 icon" />
          <span class="body-sm text-truncate">{{
            user.email ?? t('no_information')
          }}</span>
        </div>
        <div class="d-flex align-items-center">
          <Svg :src="require('@bd/assets/icons/phone.svg')" class="mr-2 icon" />
          <span class="body-sm text-truncate">{{
            user.phoneNumber ?? t('no_information')
          }}</span>
        </div>
      </div>
      <div class="chip-container">
        <Chip
          :label="userRoleLabel"
          v-if="display.chip"
          class="text-uppercase"
        />
      </div>
    </div>
    <div class="arrow">
      <span>
        <i class="pi pi-chevron-right mr-2 c-primary" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import Chip from 'primevue/chip'
import UserAvatar from '@bd/components/User/UserAvatar/UserAvatar.vue'
import {
  Svg,
  useUserNameOrPlaceholder,
  useUserRoleLabel,
  useUserType,
  useUserTypeClass,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { UserRole } from '@bd/api'

interface UserContent {
  id: number
  userRole?: UserRole[]
  roles?: UserRole[]
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  displayableId: string
  developer?: boolean
}

export default defineComponent({
  name: 'UserCard',
  components: { UserAvatar, Chip, Svg },
  props: {
    user: {
      type: Object as PropType<UserContent>,
      required: true,
    },
    displayOptions: {
      type: Array as PropType<('details' | 'id' | 'chip')[]>,
      required: true,
    },
    isIdReversed: {
      type: Boolean,
      default: false,
    },
    bgClass: {
      type: String,
      default: 'background-white',
    },
  },
  setup(props) {
    const { t } = useI18n()

    const user = computed(() => props.user)
    const userType = useUserType(user)
    const userRoleLabel = useUserRoleLabel(user)
    const userTypeClass = useUserTypeClass(userType)

    const display = computed(() => {
      return {
        details: props.displayOptions.includes('details'),
        id: props.displayOptions.includes('id'),
        chip: props.displayOptions.includes('chip'),
      }
    })

    const cardClasses = computed(() => [
      `user-type-${userTypeClass.value} ${props.bgClass}`,
    ])

    const userName = useUserNameOrPlaceholder(user)

    return {
      t,
      userType,
      userRoleLabel,
      display,
      cardClasses,
      userName,
    }
  },
})
</script>

<style lang="scss" scoped>
.card {
  border-radius: 6px;
  transition: all 0.3s;
  cursor: pointer;
  color: $dodger-blue;
  min-width: 240px;
  max-width: 280px;

  &:hover {
    box-shadow: 0 65px 230px 0 $light-shadow;
  }
  @include breakpoint-up(md) {
    padding: 25px 30px;
    min-height: 180px;
  }
  @include breakpoint-down(md) {
    padding: 15px 0;
    border-bottom: $divider-border;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include flex($justify: space-between, $align: flex-start, $direction: row);
  }
  .card-content {
    display: flex;
    justify-content: space-between;
    @include breakpoint-up(md) {
      flex-direction: column;
    }
  }
  .card-details {
    display: flex;
    justify-content: space-around;
    @include breakpoint-up(md) {
      margin-top: 20px;
    }
  }
}

.p-chip {
  min-width: 140px;
  height: 28px;
  justify-content: center;
  font-weight: 500;
}

.icon {
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
}

:deep(.user-avatar) {
  @include breakpoint-up(md) {
    height: 70px;
    width: 70px;
  }
  @include breakpoint-down(md) {
    height: 50px;
    width: 50px;
  }
  font-size: 24px;
  border-radius: 16px;
}

.user-type {
  &-seller {
    color: $dodger-blue;

    .p-chip {
      color: $dodger-blue;
      background-color: rgba($dodger-blue, 0.1);
    }
  }
  &-buyer {
    color: $zest;

    .p-chip {
      color: $zest;
      background-color: rgba($zest, 0.1);
    }
  }
  &-agent,
  &-admin,
  &-callcenter {
    color: $cerulean;

    .p-chip {
      color: $cerulean;
      background-color: $cerulean-light;
    }
  }

  &-developer {
    color: $jungle-green;

    .p-chip {
      color: $jungle-green;
      background-color: rgba($jungle-green, 0.1);
    }
  }
}

.arrow {
  display: flex;
  flex-direction: column;
  align-self: center;

  @include breakpoint-up(md) {
    display: none;
  }
}

.background-white {
  background: $white;
}
</style>
