
import { computed, defineComponent, PropType } from 'vue'
import Chip from 'primevue/chip'
import UserAvatar from '@bd/components/User/UserAvatar/UserAvatar.vue'
import {
  Svg,
  useUserNameOrPlaceholder,
  useUserRoleLabel,
  useUserType,
  useUserTypeClass,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { UserRole } from '@bd/api'

interface UserContent {
  id: number
  userRole?: UserRole[]
  roles?: UserRole[]
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  displayableId: string
  developer?: boolean
}

export default defineComponent({
  name: 'UserCard',
  components: { UserAvatar, Chip, Svg },
  props: {
    user: {
      type: Object as PropType<UserContent>,
      required: true,
    },
    displayOptions: {
      type: Array as PropType<('details' | 'id' | 'chip')[]>,
      required: true,
    },
    isIdReversed: {
      type: Boolean,
      default: false,
    },
    bgClass: {
      type: String,
      default: 'background-white',
    },
  },
  setup(props) {
    const { t } = useI18n()

    const user = computed(() => props.user)
    const userType = useUserType(user)
    const userRoleLabel = useUserRoleLabel(user)
    const userTypeClass = useUserTypeClass(userType)

    const display = computed(() => {
      return {
        details: props.displayOptions.includes('details'),
        id: props.displayOptions.includes('id'),
        chip: props.displayOptions.includes('chip'),
      }
    })

    const cardClasses = computed(() => [
      `user-type-${userTypeClass.value} ${props.bgClass}`,
    ])

    const userName = useUserNameOrPlaceholder(user)

    return {
      t,
      userType,
      userRoleLabel,
      display,
      cardClasses,
      userName,
    }
  },
})
