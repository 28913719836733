
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserData } from '@bd/components/use'
import DashboardContentSwitcher from './DashboardContentSwitcher.vue'
import DashboardProfileButton from './DashboardProfileButton.vue'
import { DashboardContentTab } from './types'
import { useAppStore } from '@bd/agent/store'
import QuickSearch from '@bd/agent/components/QuickSearch/QuickSearch.vue'
import { Svg } from '@bd/components'

const DEFAULT_DASHOARD_TAB: DashboardContentTab = 'Offers'

export default defineComponent({
  components: {
    DashboardContentSwitcher,
    DashboardProfileButton,
    QuickSearch,
    Svg,
  },
  emits: [
    'sidebar-click',
    'filter-dialog-click',
    'dashboard-switcher-select',
    'client-add-click',
  ],
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const { user } = useUserData()
    const proposalModalVisibility = ref(false)

    const meetingsCount = computed(() => store.state.calendar?.todayEventsCount)

    const activeDashboardContentTab = ref<DashboardContentTab>(
      DEFAULT_DASHOARD_TAB,
    )

    const offersState = store.state.offers

    const displayClearFilterButton = computed(() => {
      if (!offersState) {
        return false
      }
      const {
        showDrafts,
        pageSize,
        pageIndex,
        showAll,
        address,
        ...otherFilters
      } = offersState.filters
      return Object.keys(otherFilters).length > 0
    })

    const clearFilters = async () => {
      await store.dispatch('offers/clearFilterAndRefresh', {
        keep: ['showAll'],
      })
    }

    return {
      t,
      user,
      meetingsCount,
      activeDashboardContentTab,
      proposalModalVisibility,
      clearFilters,
      displayClearFilterButton,
    }
  },
})
