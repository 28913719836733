<template>
  <Loader :is-loading="isLoading" />
  <InfiniteScroll
    class="card-grid"
    :pageSize="pageSize"
    :getPage="getClientsPage"
    :getItemKey="getClientId"
    @is-loading="isLoading = $event"
    scrollElem=".dashboard-wrapper"
  >
    <template #default="{ item }">
      <UserCard
        class="client-card"
        @click="
          $router.push({ name: 'ClientDetails', params: { id: item.id } })
        "
        :user="item"
        :displayOptions="['id']"
        :isIdReversed="false"
        bgClass="card-background"
      />
    </template>
  </InfiniteScroll>
</template>

<script lang="ts">
import { computed, defineComponent, shallowRef } from 'vue'
import UserCard from '@bd/components/User/UserCard/UserCard.vue'
import { Loader, InfiniteScroll, determinePageSize } from '@bd/components'
import { ClientDto } from '@bd/api'
import { agentApi } from '@bd/api'
import { useAppStore } from '@bd/agent/store'

export default defineComponent({
  name: 'DashboardClientList',
  components: {
    UserCard,
    Loader,
    InfiniteScroll: InfiniteScroll.InfiniteScroll,
  },
  emits: ['clear-filter'],
  props: {},
  setup() {
    const store = useAppStore()
    const isLoading = shallowRef(false)

    // not reactive on purpose. InfiniteScroll component has internal logic that
    // adjusts number of pages fetched in parallel depending on container size.
    const pageSize = determinePageSize()

    const getClientsPage = computed<InfiniteScroll.GetPageFn>(() => {
      const filters = store.state.clients?.filters || {}
      return async (page) => {
        const { data } = await agentApi.clients({ ...filters, ...page })
        return data
      }
    })

    const getClientId = (client: ClientDto) => {
      return client.id || client.userId
    }

    return {
      pageSize,
      getClientsPage,
      getClientId,
      isLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.card-background {
  @include breakpoint-up(md) {
    background-color: $white;
  }
}

.client-card {
  @include breakpoint-down(md) {
    width: 100%;
    max-width: unset;
  }

  :deep(.user-avatar) {
    min-width: 50px;
  }
}
</style>
