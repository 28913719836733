import { ComputedFilter } from '@bd/store-modules/types'
import { CityWithProvinceDto } from '@bd/api'

export interface LoaderType {
  isLoading: boolean
  isEmpty: boolean
  options: {
    pageIndex: number
    contentLength: number
    totalCount: number
  }
}
export interface FilterDataType {
  activeFilters: ComputedFilter[]
  citiesAndProvinces: CityWithProvinceDto[]
}

export type DashboardContentTab = 'Offers' | 'Clients'
export enum DashboardContentListType {
  All = 'All',
  OnlyMine = 'OnlyMine',
}

export type ButtonIconType = 'Plus' | 'Phone'
