<template>
  <Button class="p-button-sm p-button-secondary p-button-rounded py-4">
    <Svg
      :src="
        icon === 'Plus'
          ? require('@bd/assets/icons/plus.svg')
          : require('@bd/assets/icons/call-phone.svg')
      "
      class="mr-2 profile-icon"
    />
    <slot />
  </Button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ButtonIconType } from './types'
import { Svg } from '@bd/components'

export default defineComponent({
  name: 'DashboardProfileButton',
  components: { Svg },
  props: {
    icon: {
      type: String as PropType<ButtonIconType>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.profile-icon {
  width: 30px;
  height: 30px;
  color: $primary-color;
  font-weight: 400;
}
</style>
