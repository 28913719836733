<template>
  <div class="dashboard-wrapper">
    <PageContent class="dashboard-content">
      <DashboardLayout
        @sidebar-click="toggleSidebar"
        @filter-dialog-click="isFiltersDialogActive = true"
        @dashboard-switcher-select="loadDashboardView"
        @client-add-click="$router.push({ name: 'AddClient' })"
      >
        <template #content>
          <DashboardOfferList
            v-if="currentTab === 'Offers'"
            :activeFilters="activeFilters"
            :citiesAndProvinces="citiesAndProvinces"
            @clear-filter="removeFilter($event)"
          />

          <DashboardClientList v-else />
        </template>
      </DashboardLayout>
    </PageContent>

    <FiltersSidebar
      v-model:visible="isSidebarActive"
      :filters="filters"
      :filtersState="filtersState"
      :citiesAndProvinces="citiesAndProvinces"
      :districts="districts"
      @add-to-queue="addToQueue"
      @filter-results="filterResults"
    />

    <FiltersDialog
      v-model:visible="isFiltersDialogActive"
      :filters="filters"
      :citiesAndProvinces="citiesAndProvinces"
      :districts="districts"
      :filtersState="filtersState"
      @add-to-queue="addToQueue"
      @filter-results="filterDialogAction"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  PageContent,
  useComputedActiveFilters,
  FiltersSidebar,
  FiltersDialog,
  useComputedFilters,
} from '@bd/components'
import DashboardClientList from '@bd/agent/components/Dashboard/DashboardClientList.vue'
import DashboardOfferList from '@bd/agent/components/Dashboard/DashboardOfferList.vue'
import DashboardLayout from '@bd/agent/components/Dashboard/DashboardLayout.vue'
import { useAppStore } from '@bd/agent/store'
import { FilterProp, Filters, ChildrenData } from '@bd/store-modules/types'
import {
  DashboardContentListType,
  DashboardContentTab,
} from '@bd/agent/components/Dashboard/types'

export default defineComponent({
  name: 'Dashboard',
  components: {
    PageContent,
    DashboardLayout,
    DashboardClientList,
    DashboardOfferList,
    FiltersSidebar,
    FiltersDialog,
  },
  setup() {
    const i18n = useI18n()
    const store = useAppStore()

    const filtersState = computed(() => {
      return store.state.offers?.filters
    })
    const offers = computed(() => store.state.offers?.offers.content || [])
    const clients = computed(() => store.state.clients?.clients.content || [])

    const currentTab = ref<DashboardContentTab>('Offers')

    const activeFilters = computed(() => {
      return useComputedActiveFilters(store.state.offers?.searchedFilters || {})
    })
    const citiesAndProvinces = computed(() => {
      return store.state.offers?.citiesAndProvinces.content?.content || []
    })

    const stateFilters = computed(() => store.state?.offers?.filters)
    const districts = computed(() => store.state.offers?.districts)
    const { filters } = useComputedFilters(stateFilters, districts)

    const isFiltersDialogActive = ref(false)

    const setFiltersAndRefresh = (
      module: DashboardContentTab,
      payload: Filters,
    ) => {
      return store.dispatch(`${module.toLowerCase()}/setFilters`, payload)
    }

    const isSidebarActive = ref(false)

    const toggleSidebar = () => {
      isSidebarActive.value = !isSidebarActive.value
    }

    const filterResults = () => {
      if (store.state.offers?.searchedFilters !== filtersState.value) {
        store.commit('offers/APPLY_FILTERS')
      }
    }

    const filterDialogAction = () => {
      filterResults()
      isFiltersDialogActive.value = false
    }

    const addToQueue = (queueObj: FilterProp) => {
      if (!queueObj?.prop) {
        return
      }
      const { prop, value } = queueObj
      store.dispatch('offers/setFilters', {
        [prop]: value,
        pageIndex: 0,
      })
      if (
        prop.includes('cityIds') &&
        filtersState.value?.cityIds?.length === 1
      ) {
        store.dispatch('offers/cityDistricts', filtersState.value?.cityIds[0])
      }
    }

    const removeFilter = (childrenArray: ChildrenData[]) => {
      childrenArray.forEach((x) => {
        const value =
          x.name === 'cityIds' || x.name === 'districtIds' ? x.value[0] : []
        store.dispatch('offers/setFilters', { [x.name]: value })
      })
      filterResults()
    }

    const loadDashboardView = (payload: {
      tab: DashboardContentTab
      option: string | undefined
    }) => {
      currentTab.value = payload.tab
      const showAll = payload.option === 'All'
      const params = { showAll }
      setFiltersAndRefresh(payload.tab, params)
      filterResults()
    }

    const selectedCities = computed(() => {
      const cityFilter = activeFilters.value.find(
        ({ filterName }) => filterName === 'city',
      )
      return cityFilter?.passedData[0].value
    })

    const searchDistricts = async (cityId: number) => {
      await store.dispatch('offers/cityDistricts', cityId)
    }

    watch(selectedCities, (value) => {
      if (value && value.length === 1) {
        searchDistricts(+value[0])
      }
    })

    loadDashboardView({
      tab: currentTab.value,
      option: DashboardContentListType.OnlyMine,
    })

    store.dispatch('offers/citiesAndProvincesList')

    return {
      ...i18n,
      currentTab,
      isSidebarActive,
      toggleSidebar,
      filters,
      addToQueue,
      filtersState,
      setFiltersAndRefresh,
      citiesAndProvinces,
      districts,
      isFiltersDialogActive,
      filterDialogAction,
      activeFilters,
      removeFilter,
      loadDashboardView,
      clients,
      offers,
      filterResults,
    }
  },
})
</script>

<style lang="scss" scoped>
@include breakpoint-down(md) {
  .dashboard-padding {
    padding: 0 20px;
  }
}

@include breakpoint-up(md) {
  .dashboard-padding {
    padding: 0 60px;
  }
}

@include breakpoint-up(md) {
  .filters-panel {
    background: $alabaster;
    border-top: 1px solid $athens-gray;
  }
}

.filters-panel {
  height: 70px;
}

.gutter-2.row {
  margin-right: -30px;
  margin-left: -30px;
}
.gutter-2 > [class^='col-'],
.gutter-2 > [class^=' col-'] {
  padding-right: 30px;
  padding-left: 30px;
}

.results-text {
  font-size: 1.375rem;
}
.dashboard-wrapper {
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

@include breakpoint-down(md) {
  :deep(.dashboard-content) {
    &.bottom-nav-visible {
      .loader-overlay,
      .loader {
        bottom: #{$mobile-navigation-height} !important;
      }
    }
  }
}
</style>
