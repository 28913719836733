
import { defineComponent, PropType } from 'vue'
import { ButtonIconType } from './types'
import { Svg } from '@bd/components'

export default defineComponent({
  name: 'DashboardProfileButton',
  components: { Svg },
  props: {
    icon: {
      type: String as PropType<ButtonIconType>,
      required: true,
    },
  },
})
