<template>
  <div class="dashboard-layout d-flex flex-column mt-4 mt-md-0">
    <div class="container-fluid">
      <div class="mobile-header d-flex d-md-none flex-column mb-3">
        <div class="welcome-container">
          <h1 class="h-1 mb-2 text-truncate">
            {{ t('welcome') }} {{ user?.firstName }}
          </h1>
          <div class="meetings-count d-flex align-items-center">
            <label>{{ t('todays_meetings_count') }}</label>
            <div class="ml-1 meetings-badge flex-centered">
              {{ meetingsCount }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex d-md-none align-items-center search--mobile">
        <QuickSearch class="quick-search" />
        <Button
          v-if="activeDashboardContentTab === 'Offers'"
          @click="$emit('filter-dialog-click')"
          class="p-button-text"
        >
          <Svg :src="require('@bd/assets/icons/plus.svg')" class="c-primary" />
        </Button>
        <Button
          v-if="activeDashboardContentTab === 'Clients'"
          @click="$emit('client-add-click')"
          icon="pi"
          class="p-button-circle p-button-sm p-button-outlined p-0 ml-2 ml-auto"
          style="height: 45px; width: 45px"
        >
          <Svg
            class="plus-icon w-100 c-primary"
            :src="require('@bd/assets/icons/plus.svg')"
          />
        </Button>
      </div>
    </div>
    <div class="dashboard-content-container">
      <div class="dashboard-content-header">
        <DashboardContentSwitcher
          v-model:activeContentTab="activeDashboardContentTab"
          @select-item="$emit('dashboard-switcher-select', $event)"
        />

        <div class="d-none d-md-block ml-auto">
          <span
            v-if="
              displayClearFilterButton && activeDashboardContentTab === 'Offers'
            "
            class="p-buttonset ml-3"
          >
            <Button
              class="p-button-sm p-button-secondary"
              @click="$emit('sidebar-click')"
            >
              <Svg
                class="input-button mr-2 c-primary"
                :src="require('@bd/assets/icons/filter.svg')"
              />
              <span class="p-button-label">{{ t('filters') }}</span>
            </Button>
            <Button
              icon="pi pi-times"
              class="p-button-sm p-button-secondary"
              @click="clearFilters"
            />
          </span>
          <Button
            v-if="
              !displayClearFilterButton &&
              activeDashboardContentTab === 'Offers'
            "
            class="p-button-sm p-button-secondary p-button-rounded ml-3"
            @click="$emit('sidebar-click')"
          >
            <Svg
              class="input-button mr-2 c-primary"
              :src="require('@bd/assets/icons/filter.svg')"
            />
            <span class="p-button-label">{{ t('filters') }}</span>
          </Button>
          <DashboardProfileButton
            v-if="activeDashboardContentTab === 'Clients'"
            icon="Plus"
            @click="$emit('client-add-click')"
            ><span class="text-500">{{
              t('clients.add')
            }}</span></DashboardProfileButton
          >
        </div>
      </div>
      <slot name="content" :activeTab="activeDashboardContentTab" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserData } from '@bd/components/use'
import DashboardContentSwitcher from './DashboardContentSwitcher.vue'
import DashboardProfileButton from './DashboardProfileButton.vue'
import { DashboardContentTab } from './types'
import { useAppStore } from '@bd/agent/store'
import QuickSearch from '@bd/agent/components/QuickSearch/QuickSearch.vue'
import { Svg } from '@bd/components'

const DEFAULT_DASHOARD_TAB: DashboardContentTab = 'Offers'

export default defineComponent({
  components: {
    DashboardContentSwitcher,
    DashboardProfileButton,
    QuickSearch,
    Svg,
  },
  emits: [
    'sidebar-click',
    'filter-dialog-click',
    'dashboard-switcher-select',
    'client-add-click',
  ],
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const { user } = useUserData()
    const proposalModalVisibility = ref(false)

    const meetingsCount = computed(() => store.state.calendar?.todayEventsCount)

    const activeDashboardContentTab = ref<DashboardContentTab>(
      DEFAULT_DASHOARD_TAB,
    )

    const offersState = store.state.offers

    const displayClearFilterButton = computed(() => {
      if (!offersState) {
        return false
      }
      const {
        showDrafts,
        pageSize,
        pageIndex,
        showAll,
        address,
        ...otherFilters
      } = offersState.filters
      return Object.keys(otherFilters).length > 0
    })

    const clearFilters = async () => {
      await store.dispatch('offers/clearFilterAndRefresh', {
        keep: ['showAll'],
      })
    }

    return {
      t,
      user,
      meetingsCount,
      activeDashboardContentTab,
      proposalModalVisibility,
      clearFilters,
      displayClearFilterButton,
    }
  },
})
</script>

<style lang="scss" scoped>
$content-border-radius: 30px;

.dashboard-layout {
  flex-basis: 100%;
}

.meetings-count {
  > label {
    color: $comet;
  }
  .meetings-badge {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: $zest;
    color: $white;
  }
}

.dashboard-content-container {
  flex: 1 0 auto;
  padding: 1.5rem;
  background-color: $alabaster;

  @include breakpoint-down(md) {
    height: calc(100% - #{$mobile-navigation-height});
    border-top-left-radius: $content-border-radius;
    border-top-right-radius: $content-border-radius;
  }
}

.dashboard-content-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  @include breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }
}

.filters-button {
  box-shadow: 0 1px 2px 0 rgba(23, 25, 51, 0.08);
  border-radius: 6px;
  background-color: $white;
  height: 55px;
  padding: 0 2rem 0 1rem;
  span.p-button-label {
    color: $port-gore;
    font-size: 0.875rem;
    font-weight: 500;
  }
  > img {
    border-radius: 50%;
    padding: 0.25rem;
    border: 1px solid $athens-gray;
  }
}

.search--mobile {
  margin: 0 0.5rem;

  .quick-search {
    margin-right: 0.5rem;
    flex: 1;

    :deep(input) {
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(23, 25, 51, 0.08);
    }
  }
}

.plus-icon {
  @include uniform-size(100%);
}

.input-button {
  @include uniform-size(16px);
}
</style>
